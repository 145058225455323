export default () => {
  const $ja = $('.js-ja');
  const $en = $('.js-en');
  const path = location.pathname;

  /* EN >> JP */
  const jaPath = path.replace('/en/', '/');
  $ja.attr("href", jaPath);

  /* JP >> EN */
  const en_not_exists = [
    '/securitypolicy',
    '/corporate-information/vision/',
    '/corporate-information/global-innovation/',
  ];

  if (en_not_exists.some(value => jaPath.indexOf(value) > -1) === false){
    const enPath = jaPath.replace('/', '/en/');
    $en.attr("href", enPath);
  }
}
