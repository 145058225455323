export default () => {
  const $accordion = $('.js-accordion');

  $accordion.each(function() {
    const $accordionBtn  = $('.js-accordionBtn', this);

    $accordionBtn.on('click', function() {
      $(this).parents('.js-accordion')
        .stop()
        .addClass('is-animation')
        .toggleClass('is-active')
        .delay(900).queue(function() {
          $(this).removeClass('is-animation').dequeue();
        });
    });
  });
};
