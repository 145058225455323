export default class ExecutiveModal {
  constructor() {
    this.body = $('body');
    this.toggle = $('.js-executive-modal-button-toggle');
    this.modal = $('.executive-modal');
    this.move = $('.executive-modal-pager-item');
    this.isOpen = 0;
    this.init();
  }

  init() {
    history.pushState(null, null, null);

    this.toggle.on('click', e => {
      this.isOpen === 0 ? this.open($(e.currentTarget).data('targetModal')) : this.close();
    });

    this.move.on('click', e => {
      this.close(false);
      this.open($(e.currentTarget).data('targetModal'), false);
    });

    // ブラウザバックでモーダルを閉じる
    window.addEventListener('popstate', e => {
      if (this.isOpen) {
        history.pushState(null, null, null);
        this.close();
      } else {
        history.go(-2);
      }
    });
  }

  open(target, fade = true) {
    const elem = $(document.getElementById(target));

    this.isOpen = 1;

    if (fade) {
      elem.stop().fadeIn();
    } else {
      elem.show();
    }

    this.body.css('overflow', 'hidden');
  }

  close(fade = true) {
    this.isOpen = 0;

    if (fade) {
      this.modal.stop().fadeOut();
    } else {
      this.modal.hide();
    }

    this.body.css('overflow', '');
  }
}
