import Swiper from "swiper/dist/js/swiper.min.js";

export default class Slider {
  constructor() {
    let swiper;
    let swiperOption = {
      watchOverflow: true,
      speed: 1000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
    swiper = new Swiper(".swiper-container", swiperOption);
  }
}
