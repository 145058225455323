export default class tabChange {
  constructor(btn, area) {
    this.tabBtn = [].slice.call(document.getElementsByClassName(btn));
    this.tabArea = [].slice.call(document.getElementsByClassName(area));

    for (let i = 0; i < this.tabBtn.length; i++) {
      let $target = this.tabBtn[i];

      $target.addEventListener("click", () => {
        let $btnCat = $target.getAttribute("data-tab");
        this.tabChange($btnCat, $target);
      });
    }
  }

  tabChange(_btnCat, _target) {
    for (let i = 0; i < this.tabArea.length; i++) {
      let $tabArea = this.tabArea[i];
      let $areaCat = $tabArea.getAttribute("id");
      let $selectArea = document.getElementById($areaCat);

      if (_btnCat == $areaCat) {
        _target.classList.add("is-active");
        $selectArea.classList.add("is-active");
      } else {
        this.tabBtn[i].classList.remove("is-active");
        $selectArea.classList.remove("is-active");
      }
    }
  }
}
