import Swiper from "swiper/dist/js/swiper.min.js";

export default class IndexBusinessSlider {
  constructor() {
    let swiper;

    $(window).on('load resize', () => {
      const w = $(window).width();

      if (w <= 1400) {
        if (swiper) {
          return;
        } else {
          swiper = new Swiper('.business-list-wrapper', {
            loop: true,
            speed: 1000,
            spaceBetween: 10,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              1400: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 1.15,
                centeredSlides: true,
              }
            }
          });
        }
      } else {
        if (swiper) {
          swiper.destroy();
          swiper = undefined;
        }
      }
    });

  }
}
