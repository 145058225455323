export default () => {
  const url = $(location).attr("href");
  const headerHeight = $(".l-header").outerHeight();
  const $body = $("html, body");

  window.addEventListener("load", () => {
    if(url.indexOf("#") != -1) {
      let anchor = url.split("#");
      let target = $("#" + anchor[anchor.length - 1]);

      if(target.length) {
        let position = Math.floor(target.offset().top) - headerHeight;
        $body.animate({ scrollTop: position }, 500);
      }
    }
  });

  $('a[href^="#"]').on("click", function () {
    let _this = $(this);
    let href = _this.attr("href"),
        target = $(href == "#" || href == "" ? "html" : href);
    let position = target.offset().top - headerHeight

    $body.animate({ scrollTop: position }, 500);

    return false;
  });
};
