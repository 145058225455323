export default class FaqAccordion {
  constructor() {
    // ハッシュタグ判定
    const urlHash = location.hash;
    const $accordionBtn = $(urlHash + ' .js-accordionFaqBtn');
    const $accordionCont = $(urlHash + ' .js-accordionFaqCont');

    if(urlHash.match(/\#faq/)) {
      $accordionBtn.addClass('is-active');
      $accordionCont.addClass('is-active');
    }

    // アコーディオン設定
    const $accordionFaq = $('.js-accordionFaq');

    $accordionFaq.each(function() {

      const $accordionBtn  = $('.js-accordionFaqBtn', this);
      const $accordionCont  = $('.js-accordionFaqCont', this);

      $accordionBtn.on('click', function() {
        $(this).toggleClass('is-active');
        $accordionCont.slideToggle();
      });
    });
  }
}
