const $ = require('jquery');
import validator from 'validator';
const config = require('./config').config;
const configEn = require('./config').configEn;

export default class ContactForm {

  constructor() {
    this.invalidCount = 0;
    this.submitButtonId = 'contactSubmit';
    this.formClass = '.contact-form';
    this.apiURL = 'https://szr4vwt431.execute-api.ap-northeast-1.amazonaws.com/v1/contact/submit?';
    this.init();
  }

  init ()
  {
    const self = this;
    // contact
    let contactSubmit = document.getElementById(this.submitButtonId);

    if(contactSubmit) {
      contactSubmit.addEventListener("click", function (e) {
        e.preventDefault();
        self.invalidCount = 0;
        let contactForm = document.querySelector(self.formClass);

        let submitData = self.serialize(contactForm);

        // バリデーションエラーがなければ送信
        if (self.invalidCount == 0 ) {

          if (!$(':input[name="approve"]').prop('checked')) {
            let text = (document.documentElement.lang === 'en') ? 'Please read and agree to the Privacy policy.' : 'プライバシーポリシーへの同意にチェックをしてください';

            alert(text);
            return false;
          } else {
            $('#contactSubmit').prop('disable', true);
            self.sendmail(submitData);
          }
        }
      });
    }
  }
  // Ajaxリクエスト
  sendmail(data)
  {
    fetch(this.apiURL + data, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      if (response.status === 200) {
        let url = (document.documentElement.lang === 'en') ? '/en/contact/thanks/' : '/contact/thanks/';

        location.href = url;
      }
    }).then(function (json) {
      console.log(json);
    });
  }

	// 入力値をシリアライズしてバリデーション実行後、json文字列として返却
	serialize(form) {
		let obj = {};
		let elements = form.querySelectorAll("input:not(.ignore), select, textarea:not(.g-recaptcha-response)");

		// バリデーションで変更したスタイルとDOMをリセット
		this.invalidStyleReset();

		for (let i = 0; i < elements.length; ++i) {
			let element = elements[i];
			let name = element.name;
			let value = element.value;
			let item = (document.documentElement.lang === 'en') ? configEn.validation[name] : config.validation[name];

			// バリデーション実行
			this.validation(item, name, value);

			// フォーム入力値をオブジェクトに変換
			if (name) {
				obj[name] = value;
			}
    }
    const URLparams = new URLSearchParams(Object.entries(obj));
		return URLparams.toString();
	}

  // バリデーションで付与したスタイルをリセット
  invalidStyleReset ()
  {
    $(':input').removeClass('error');
    $('.error-message').remove();
  }

  // configからバリデーション設定値を読み取り入力項目をチェック
  validation(item, name, value)
  {
    // バリデーションにオプション指定があるもの
    if (typeof item.options !== "undefined"){
      for (let j = 0; j < item.check.length; j++) {
        // 複数チェック項目がありオプション指定が無い場合
        if (item.options[j] == null) {
          if (!validator[item.check[j]](value)) {
            this.addInvalidStyle(name, item.field, item.message[j]);
            this.invalidCount++;
          }
        } else {
          if (!validator[item.check[j]](value, item.options[j])) {
            this.addInvalidStyle(name, item.field, item.message[j]);
            this.invalidCount++;
          }
        }
      }
    // バリデーションにオプション指定がないもの
    } else {
      for (let j = 0; j < item.check.length; j++) {
        if (name == 'retype_email') {
          if (!validator[item.check[j]]($('input[name="email"]').val(), $('input[name="retype_email"]').val())){
            this.addInvalidStyle(name, item.field, item.message[j]);
            this.invalidCount++;
          }
        } else {
          if (!validator[item.check[j]](value)){
            this.addInvalidStyle(name, item.field, item.message[j]);
            this.invalidCount++;
          }
        }
      }
    }
  }

  // バリデーションエラーの項目にスタイルをセット
  addInvalidStyle(name, field, message)
  {
    let text = (document.documentElement.lang === 'en') ? message : field + message;

    $(':input[name="'+name+'"]').addClass('error');
    $(':input[name="'+name+'"]').after('<div class="error-message">'+ text +'</div>');
  }
}
