exports.config = {
    validation: {
        type: {
            field : 'お問い合わせ項目',
            check : ['isLength'],
            options : [{min: 1, max: 50}],
            message: ['は必須です']
        },
        company_name: {
            field : '会社名',
            check : ['isLength'],
            options : [{max: 50}],
            message: ['は50文字以内で入力してください']
        },
        customer_name: {
            field : '氏名',
            check : ['isLength'],
            options : [{min: 1, max: 50}],
            message: ['は必須です。1〜50文字で入力してください']
        },
        email: {
            field : 'メールアドレス',
            check : ['isEmail'],
            message: ['の形式がただしく有りません']
        },
        retype_email: {
            field : '確認用メールアドレス',
            check : ['equals'],
            message: ['が一致しません']
        },
        description: {
            field : 'お問い合わせ内容',
            check : ['isLength'],
            options : [{min:1, max: 10000}],
            message: ['は必須です。1〜10000文字以内で入力してください']
        }
    }
};

exports.configEn = {
    validation: {
        type: {
            field : 'Topic',
            check : ['isLength'],
            options : [{min: 1, max: 50}],
            message: ['Please select topics.']
        },
        company_name: {
            field : 'Company name',
            check : ['isLength'],
            options : [{max: 50}],
            message: ['Please enter your company name within 50 characters.']
        },
        customer_name: {
            field : 'Name',
            check : ['isLength'],
            options : [{min: 1, max: 50}],
            message: ['Please enter your name in 1 to 50 characters.']
        },
        email: {
            field : 'E-mail Address',
            check : ['isEmail'],
            message: ['Please enter your email address.']
        },
        retype_email: {
            field : 'E-Mail Address (Confirm)',
            check : ['equals'],
            message: ['E-Mail Address does not match.']
        },
        description: {
            field : 'Your Inquiry',
            check : ['isLength'],
            options : [{min:1, max: 10000}],
            message: ['Please enter your inquiry in 1 to 10000 characters.']
        }
    }
};
