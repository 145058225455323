import Swiper from "swiper/dist/js/swiper.min.js";

export default class InformationNumbersSlider {
  constructor() {
    let swiper;

    $(window).on('load resize', () => {
      const w = $(window).width();

      if (w <= 1024) {
        if (swiper) {
          return;
        } else {
          swiper = new Swiper('.numbers-box-list-wraper', {
            loop: true,
            speed: 1000,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 40,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            }
          });
        }
      } else {
        if (swiper) {
          swiper.destroy();
          swiper = undefined;
        }
      }
    });

  }
}
