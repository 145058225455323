export default () => {
  const $hrader    = $('.l-header'),
        $hamburger = $('.hamburger'),
        $hraderBtn = $('.header-trigger'),
        $html      = $('html');

  $hraderBtn.on('click', function() {
    $hamburger.addClass('is-animation');
    $hrader.toggleClass('is-active');
    $html.toggleClass('is-hidden');
  });
}
