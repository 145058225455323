export default () => {
  const ua = navigator.userAgent;

  if (
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") == -1) ||
    ua.indexOf("iPad") > 0 ||
    ua.indexOf("Kindle") > 0 ||
    ua.indexOf("Silk") > 0
  ) {
    // PCタブレット端末
    $("meta[name='viewport']").attr("content", "width=1400");
  } else if (
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0) ||
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("Blackberry") > 0
  ) {
    // SP端末
  } else if (
    navigator.platform == "MacIntel" &&
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1
  ) {
    if (navigator.standalone !== undefined) {
      // iPad OS Safari
      $("meta[name='viewport']").attr("content", "width=1400");
    } else {
      // macOS Safari
    }
  }
};
