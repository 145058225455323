
import ScrollHint from 'scroll-hint';

export default class LibraryScroll {
  constructor() {
    new ScrollHint('.js-scrollable', {
      i18n: {
        scrollable: 'スクロールできます'
      }
    });
  }
}
