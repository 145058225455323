import "jquery-match-height";
import 'remodal/dist/remodal.js';

import UA from "./utils/UA";
import SmoothScroll from "./utils/SmoothScroll";
import Sticky from './utils/Sticky';
import headerNav from "./utils/headerNav";
import Accordion from "./utils/Accordion";
import Lang from "./utils/Lang";

import Slider from "./class/Slider";
import Tab from "./class/Tab";
// import Stock from "./class/Stock";
import LibraryScroll from "./class/LibraryScroll";
import FaqAccordion from "./class/FaqAccordion";
import ExecutiveModal from "./class/ExecutiveModal";
import IndexUsecaseSlider from "./class/IndexUsecaseSlider";
import IndexBusinessSlider from "./class/IndexBusinessSlider";
import InformationNumbersSlider from "./class/InformationNumbersSlider";
import ContactForm from "./class/contact";

class Main {
  constructor() {
    this.getId();
    this.init();
  }

  init() {
    new Slider();
    new Tab("js-tab", "js-tab-area");
    new LibraryScroll();
    new ExecutiveModal();

    UA();
    SmoothScroll();
    Sticky();
    headerNav();
    Accordion();
    Lang();
  }

  getId() {
    let body_id = document.body.getAttribute("id");

    switch (body_id) {
      case "index":
        return new IndexUsecaseSlider(),
              new IndexBusinessSlider(),
              $('.js-match-height').matchHeight({
                property: 'min-height',
              });

      case "corporate-information":
        return new InformationNumbersSlider();

      case "investors":
        // return new Stock();

      case "faq":
        return new FaqAccordion();

      case "contact":
        return new ContactForm();

      default:
    }
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new Main();
});
