import Swiper from "swiper/dist/js/swiper.min.js";

export default class IndexUsecaseSlider {
  constructor() {
    let swiper;
    let swiperOption = {
      loop: true,
      speed: 1000,
      slidesPerView: 1.1,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1400: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1.1,
        },
      },
    };
    swiper = new Swiper(".swiper-container-usecase", swiperOption);
  }
}
