export default () => {

  const StickyState = require('sticky-state');

  function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    return div.style.position.indexOf('sticky') !== -1;
  }

  function callStickyState() {
    return new StickyState(document.querySelectorAll('.sticky'));
  }

  if (!detectSticky()) {
    callStickyState();
  }

}
